import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const Hero = () => {
  return (
    <Wrapper>
      <header className='hero'>
        <StaticImage
          src='../images/hero.jpg'
          alt='letters'
          className='hero-img'
          placeholder='blurred'
          layout='fullWidth'
        ></StaticImage>
        <div className='hero-container'>
          <div className='hero-text'>
            <h1 className='logo'>
              <span className='logo-first-word'>pixel</span>
              <span className='logo-second-word'>juice</span>
            </h1>
          </div>
        </div>
      </header>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .hero {
    height: 100vh;
    position: relative;
    margin-bottom: 0rem;
    overflow: hidden;

    .hero-img {
      height: 100%;
      border-radius: var(--border-radius);

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-animation: zoomin 10s ease-in-out forwards;
      /*  a forwards teszi lehetővé, hogy a 100%-os állapotban maradajon, különben visszaugrik a 0%-ra!       */
      animation: zoomin 10s ease-in-out forwards;
      transition: all 0.5s ease-in-out;
      overflow: hidden;
    }

    .hero-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background: rgba(0, 0, 0, 0.75); */
      border-radius: var(--border-radius);

      .hero-text {
        .logo {
          text-align: center;
          animation: logo-zoomin 10s linear forwards;

          .logo-first-word {
            color: var(--logo-first-word-color);
            font-family: var(--logo-first-word-font);
          }
          .logo-second-word {
            color: var(--logo-second-word-color);
            font-family: var(--logo-second-word-font);
            font-size: 6.8rem;
          }
        }
      }
    }
    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
      .quote {
        font-size: 1.2rem;
        h4 {
          font-size: 1rem;
        }
      }
    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
    }

    /* X-Large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
      .hero-text {
        padding-bottom: 5%;
        padding-left: 10%;
        padding-right: 10%;
        text-align: center;
      }
    }

    /* XX-Large devices (larger desktops, 1400px and up) */
    @media (min-width: 1400px) {
    }
  }

  /* Zoom in Keyframes */
  @-webkit-keyframes zoomin {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1.5);
    }
  }
  @keyframes zoomin {
    0% {
      transform: scale(1);
      filter: grayscale(0%);
      opacity: 1;
    }
    /* 50% {
      transform: scale(1.25);
      filter: grayscale(50%);
      opacity: 0.8;
    } */
    100% {
      transform: scale(1.5);
      filter: grayscale(100%);
      opacity: 0.2;
    }
  } /*End of Zoom in Keyframes */

  @keyframes logo-zoomin {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    /* 50% {
      transform: scale(1.25);
      filter: grayscale(50%);
      opacity: 0.8;
    } */
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export default Hero;
